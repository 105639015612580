/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "page_components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "page_components/Navbars/DashboardNavbar";
import Footer from "page_components/Footer";

//Redux
import { useSelector } from "react-redux";

//Supabase
import { supabase } from "supabaseClient";

function Technicians() {
  const companyID = useSelector((state) => state.user.companyID);
  const [technicianList, setTechnicianList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const mapPageToNextCursor = useRef({});
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [queryOptions, setQueryOptions] = useState({});
  const page_size = 10;
  const [availableLicenses, setAvailableLicenses] = useState(0);
  const [usedLicenses, setUsedLicenses] = useState(0);

  const fetchRows = async (newPage) => {
    setLoading(true);
    const { from, to } = getPagination(newPage, page_size);

    const {
      data: techData,
      count,
      error: techError,
    } = await supabase
      .from("technician")
      .select("*", {
        count: "exact",
      })
      .eq("company_id", companyID)
      .eq("status", "enabled")
      .order("first_name", { ascending: false })
      .range(from, to);

    if (techError) {
      console.log(techError);
    }
    setTechnicianList(techData);
    setRowCount(count);
    setLoading(false);

    const { data: companyData, error: companyError } = await supabase
      .from("company")
      .select("account_licenses")
      .eq("id", companyID);

    if (companyError) {
      console.log(companyError);
    }
    setAvailableLicenses(companyData[0].account_licenses);
  };

  const updateTechStatus = async (id, status) => {
    const { error } = await supabase
      .from("technician")
      .update({ status: status })
      .eq("id", id);
    if (error) {
      console.log(error);
    }
  };

  const getPagination = (page, size) => {
    const limit = size ? +size : 3;
    const from = page ? page * limit : 0;
    const to = page ? from + size - 1 : size - 1;

    return { from, to };
  };

  React.useEffect(() => {
    //Get docs
    fetchRows(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyID]);

  useEffect(() => {
    renderRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicianList]);

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handlePageChange = (paginationModel) => {
    if (loading === false) {
      setCurrentPage(paginationModel.page);
      fetchRows(paginationModel.page);
    }
  };

  const renderRows = () => {
    if (rows.length > 0) {
      setRows([]);
    }
    var items = [];
    var enabledAccounts = 0;
    for (let index = 0; index < technicianList.length; index++) {
      const doc = technicianList[index];
      //const rowNum = rowIndex + index;
      items.push({
        id: doc.id,
        firstName: doc.first_name,
        lastName: doc.last_name,
        email: doc.email,
        city: doc.city,
        state: doc.state,
        status: doc.status,
      });

      if (doc.status === "enabled") {
        enabledAccounts += 1;
      }
    }
    setUsedLicenses(enabledAccounts);
    setRows(Array.from(items));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 0, sortable: false },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: false,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      sortable: false,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      sortable: false,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Technicians
                </MDTypography>
              </MDBox>
              <MDBox mx={3} mt={2}>
                <MDTypography variant="body2">
                  Only enabled technician accounts are shown.
                </MDTypography>
                <MDTypography variant="body2">
                  To enabled or disable accounts use the "Manage Accounts" section in settings.
                </MDTypography>
              </MDBox>
              <Box sx={{ height: 500, width: "100%", padding: 2 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSizeOptions={[page_size]}
                  rowCount={rowCount}
                  paginationMode="server"
                  paginationModel={{page: currentPage, pageSize: page_size}}
                  onPaginationModelChange={handlePageChange}
                  loading={loading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  //checkboxSelection
                  disableSelectionOnClick
                  keepNonExistentRowsSelected
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  getRowHeight={({ id, densityFactor }) => {
                    return 50;
                  }}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Technicians;
