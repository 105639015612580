/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "page_components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "page_components/Navbars/DashboardNavbar";
import Footer from "page_components/Footer";
import ServiceDialog from "page_components/ServiceDialog";

//Redux
import { useSelector } from "react-redux";

import { format, addSeconds } from "date-fns";
import { useEffect, useState } from "react";

//Supabase
import { supabase } from "supabaseClient";

function SprayServices() {
  const companyID = useSelector((state) => state.user.companyID);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowIndex, setRowIndex] = React.useState(0);
  const [selectionModel, setSelectionModel] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [queryOptions, setQueryOptions] = React.useState({});
  const page_size = 10;

  const fetchRows = async (newPage) => {
    setLoading(true);
    const { from, to } = getPagination(newPage, page_size);

    const { data, count, error } = await supabase
      .from("service")
      .select("*, technician(first_name, last_name, email), chemical(name)", {
        count: "exact",
      })
      .eq("company_id", companyID)
      .order("start_time", { ascending: false })
      .range(from, to);

    setServiceList(data);
    setRowCount(count);
    setLoading(false);
  };

  const getPagination = (page, size) => {
    const limit = size ? +size : 3;
    const from = page ? page * limit : 0;
    const to = page ? from + size - 1 : size - 1;

    return { from, to };
  };

  useEffect(() => {
    //Get docs
    fetchRows(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyID]);

  useEffect(() => {
    renderRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceList]);

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handlePageChange = (paginationModel) => {
    if (loading === false) {
      setCurrentPage(paginationModel.page);
      fetchRows(paginationModel.page);
    }
  };

  const renderDetailsButton = (params) => {
    const doc = params.row.details;
    return (
      <strong>
        <ServiceDialog
          date={format(new Date(doc.start_time), "MMM do yyyy")}
          time={format(new Date(doc.start_time), "h:mm bbb")}
          techName={(doc.technician?.first_name ?? "") + " " + (doc.technician?.last_name ?? "")}
          techEmail={(doc.technician?.email ?? "")}
          sprayVol={doc.total_gallons_sprayed.toFixed(2) + " Gallons"}
          chemical={doc.chemical?.name ?? "Not Recorded"}
          linFeet={doc.linear_feet.toFixed(0)}
          timeSpraying={format(
            addSeconds(new Date(0), doc.time_spent_spraying),
            "mm:ss"
          )}
          serviceId={doc.id}
          street={doc["street"] !== undefined ? doc.street : "unavailable"}
          city={doc["city"] !== undefined ? doc.city : "unavailable"}
          state={doc["state"] !== undefined ? doc.state : "unavailable"}
          zipCode={doc["zip_code"] !== undefined ? doc.zip_code : "unavailable"}
         lat={doc['latitude']}
         long={doc['longitude']}
        ></ServiceDialog>
      </strong>
    );
  };

  const renderRows = () => {
    if (rows.length > 0) {
      setRows([]);
    }
    var items = [];
    for (let index = 0; index < serviceList.length; index++) {
      const doc = serviceList[index];
      const street = doc["street"] !== undefined ? doc.street : "unavailable";
      const city = doc["city"] !== undefined ? doc.city : "unavailable";
      const rowNum = rowIndex + index;
      items.push({
        id: rowNum,
        details: doc,
        date_time: format(new Date(doc.start_time), "MMM do yyyy, h:mm bbb"),
        technician: (doc.technician?.first_name ?? "") + " " + (doc.technician?.last_name ?? ""),
        total_sprayed: doc.total_gallons_sprayed.toFixed(2) + " G",
        linear_feet: doc.linear_feet.toFixed(0),
        street: street,
        city: city,
      });
    }
    setRows(Array.from(items));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 0, sortable: false },
    {
      field: "details",
      headerName: "Service Details",
      width: 150,
      renderCell: renderDetailsButton,
      sortable: false,
    },
    {
      field: "date_time",
      headerName: "Date & Time",
      width: 210,
      sortable: false,
    },
    {
      field: "technician",
      headerName: "Technician",
      width: 110,
      sortable: false,
    },
    {
      field: "total_sprayed",
      headerName: "Total Sprayed",
      width: 120,
      sortable: false,
    },
    {
      field: "linear_feet",
      headerName: "Linear Feet",
      width: 110,
      sortable: false,
    },
    {
      field: "street",
      headerName: "Street",
      width: 200,
      sortable: false,
    },
    {
      field: "city",
      headerName: "City",
      width: 110,
      sortable: false,
    },
  ];


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Spray Services
                </MDTypography>
              </MDBox>
              <Box sx={{ height: 500, width: "100%", padding: 2 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSizeOptions={[page_size]}
                  rowCount={rowCount}
                  paginationMode="server"
                  paginationModel={{page: currentPage, pageSize: page_size}}
                  onPaginationModelChange={handlePageChange}
                  loading={loading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  //checkboxSelection
                  disableSelectionOnClick
                  keepNonExistentRowsSelected
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  getRowHeight={() => "auto"}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SprayServices;
