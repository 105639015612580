import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import { CircularProgress, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// React components
import MDBox from "theme_components/MDBox";

// React example components
import Sidenav from "page_components/Sidenav";
import Configurator from "page_components/Configurator";

// React themes
import theme from "assets/theme";

// React Dark Mode themes
import themeDark from "assets/theme-dark";

// React routes
import routes from "routes";

// React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-white.png";
import brandDark from "assets/images/logo-main-blue.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase_settings";


//Supabase
import { createClient } from "@supabase/supabase-js";

// react-router-dom components
import { useNavigate } from "react-router-dom";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  //Firebase auth state
  // eslint-disable-next-line no-unused-vars
  const [user, loading, error] = useAuthState(auth);

  //Initialize Supabase
  // Create a single supabase client for interacting with your database
  const supabase = createClient(
    "https://jvyohcszatcqqldqlszr.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imp2eW9oY3N6YXRjcXFsZHFsc3pyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzE0NjQ4ODMsImV4cCI6MTk4NzA0MDg4M30.c9iwbwSOvKrca0iYxejO4wGozkEx52BQ85oCbRLV-Ro"
  );

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  const navigateToProfileScreen = () => {
      navigate("/profile");
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
    <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  //Show loading icon if firebase is loading
  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <CircularProgress />{" "}
        </Grid>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Calisto"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/*<Configurator />
          {configsButton}*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
