// React
import { useState, useEffect } from "react";

// @mui material components
import {
  Card,
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
  Box,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";
import MDInput from "theme_components/MDInput";
import MDButton from "theme_components/MDButton";

// Authentication layout components
import LogoLayout from "pages/authentication/components/LogoLayout";

// Navigation
import { useNavigate } from "react-router-dom";
import { supabase } from "supabaseClient";

//Redux
import { useDispatch } from "react-redux";
import {
  setName,
  setCompany,
  clearUserId,
  setUserId,
  setIsCalistoUser,
} from "../../../redux/userSlice";

//Date
import { formatISO } from "date-fns";

//Images
import googlePlayBadge from "assets/images/google-play-badge.png";
import appStoreBadge from "assets/images/apple-app-store-badge.svg";

function SetPassword() {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAdminView, setShowAdminView] = useState(false);
  const [showTechnicianView, setShowTechnicianView] = useState(false);
  const [showMasterView, setShowMasterView] = useState(false);
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();

  const updatePassword = async () => {
    //Check that password 1 and 2 are the same
    setError(false);
    setLoading(true);
    if (password1 === password2) {
      const { data: login_data, error: login_error } =
        await supabase.auth.updateUser({
          password: password1,
        });

      console.log(login_data);

      if (login_data && login_data.user !== null) {
        await setUsersInfo(login_data.user.id);
        dispatchRedux(setUserId(login_data.user.id));
      }
      setLoading(false);
      if (login_error) {
        setError(true);
        setErrorText(login_error.message);
      } else {
        setError(false);

        //Get the type of user
        const { data: tech_data, error: tech_error } = await supabase
          .from("technician")
          .select("*")
          .eq("auth_id", login_data.user.id);

        const { data: admin_data, error: admin_error } = await supabase
          .from("admin")
          .select("*")
          .eq("auth_id", login_data.user.id);

        console.log(tech_data);
        console.log(admin_data);

        if (admin_data.length > 0) {
          if (tech_data.length > 0) {
            setShowMasterView(true);
          } else {
            setShowAdminView(true);
          }
        } else {
          if (tech_data.length > 0) {
            setShowTechnicianView(true);
          } else {
            setError(true);
            setErrorText(
              "Account could not be found for login credentials. Please contact support@getcalisto.com to resolve this issue."
            );
          }
        }

        //setShowReturnButton(true);
      }
    } else {
      setLoading(false);
      setError(true);
      setErrorText("Passwords do not match");
    }
  };

  const setUsersInfo = async (uid) => {
    const { data, error } = await supabase
      .from("admin")
      .select("*")
      .eq("auth_id", uid);
    if (error) {
      console.log(error);
      await dispatchRedux(clearUserId());
    }
    if (data) {
      const admin = data[0];
      const adminName = {
        firstName: admin.first_name,
        lastName: admin.last_name,
      };
      await dispatchRedux(setName(adminName));
      await dispatchRedux(setCompany(admin.company_id));
      if (admin.company_id === 1) {
        await dispatchRedux(setIsCalistoUser("true"));
      }

      //Update login date
      const { error } = await supabase
        .from("admin")
        .update({ last_login_at: formatISO(new Date()) })
        .eq("id", admin.id);
    }
  };

  const handlePassword1Change = (e) => {
    setPassword1(e.target.value);
  };

  const handlePassword2Change = (e) => {
    setPassword2(e.target.value);
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  if (showAdminView === true) {
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="white"
              mx={10}
            >
              Create New Password
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={2} px={3} textAlign="center">
            <MDTypography
              display="inline-block"
              variant="h4"
              color="dark"
              pt={1}
            >
              The password for your admin account is now set!
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={2} px={3} textAlign="center">
            <MDTypography
              display="inline-block"
              variant="subtitle1"
              color="dark"
              pb={1}
            >
              To start using your account, use the button below access to the
              dashboard.
            </MDTypography>
          </MDBox>
          <MDBox mt={2} mb={2} mx={3}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={navigateToDashboard}
            >
              Login To Dashboard
            </MDButton>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  } else if (showTechnicianView === true) {
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="white"
              mx={10}
            >
              Create New Password
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={2} px={3} textAlign="center">
            <MDTypography
              display="inline-block"
              variant="h4"
              color="dark"
              pt={1}
            >
              The password for your technician account is now set!
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={2} px={3} textAlign="center">
            <MDTypography
              display="inline-block"
              variant="subtitle1"
              color="dark"
              pb={1}
            >
              To start using your account, use one of the buttons below to
              download the Calisto Connect mobile app.
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={2} px={3}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={6} lg={6}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.getcalisto.insight"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayBadge}
                      width={250}
                      height={100}
                      alt="Google Play Badge"
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <a
                    href="https://testflight.apple.com/join/hxuYfQ19"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appStoreBadge}
                      width={240}
                      height={70}
                      alt="App Store Badge"
                    />
                  </a>
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  } else if (showMasterView === true) {
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="white"
              mx={10}
            >
              Create New Password
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={2} px={3} textAlign="center">
            <MDTypography
              display="inline-block"
              variant="h4"
              color="dark"
              pt={1}
            >
              The password for your master account is now set!
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={2} mx={20} textAlign="center">
            <MDTypography
              display="inline-block"
              variant="subtitle1"
              color="dark"
              pb={1}
            >
              You now have access to our mobile app and dashboard. To start
              using your account, use the login button to access the dashboard
              or one of the app store badges to download the Calisto Connect
              mobile app.
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={2} px={3}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={6} lg={6}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.getcalisto.insight"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayBadge}
                      width={250}
                      height={100}
                      alt="Google Play Badge"
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <a
                    href="https://testflight.apple.com/join/hxuYfQ19"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appStoreBadge}
                      width={240}
                      height={70}
                      alt="App Store Badge"
                    />
                  </a>
                </Box>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={2} mx={3}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={navigateToDashboard}
              >
                Login To Dashboard
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  } else {
    if (error === true) {
      return (
        <LogoLayout>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="white"
                mx={10}
              >
                Set Account Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="New Password"
                    variant="standard"
                    fullWidth
                    onChange={handlePassword1Change}
                  />
                </MDBox>
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="Confirm New Password"
                    variant="standard"
                    fullWidth
                    onChange={handlePassword2Change}
                  />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={updatePassword}
                  >
                    Submit Password
                  </MDButton>
                </MDBox>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorText}
                </Alert>
              </MDBox>
            </MDBox>
          </Card>
        </LogoLayout>
      );
    }
    if (loading === true) {
      return (
        <LogoLayout>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="white"
                mx={10}
              >
                Set Account Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="New Password"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="Confirm New Password"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mt={4} mb={1} textAlign="center">
                  <CircularProgress />{" "}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </LogoLayout>
      );
    }
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="white"
              mx={10}
            >
              Set Account Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="text"
                  label="New Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePassword1Change}
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  type="text"
                  label="Confirm New Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePassword2Change}
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={updatePassword}
                >
                  Submit Password
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  }
}

export default SetPassword;
