//React Router
import { Navigate, useLocation } from "react-router-dom";

//Firebase
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase_settings";
import {
  where,
  query,
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

//Date
import { formatISO, parseISO, differenceInMinutes } from "date-fns";

//Supabase
import { supabase } from "supabaseClient";
import React from "react";

//Styling
import MDBox from "theme_components/MDBox";
import { CircularProgress } from "@mui/material";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setName, setCompany, setUserId, setIsCalistoUser } from "../redux/userSlice";

export const RequireAuth = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [session, setSession] = React.useState(null);
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const companyID = useSelector((state) => state.user.companyID);

  React.useEffect(() => {
    getAuthSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuthSession = async () => {
    setLoading(true);
    const { data, error } = await supabase.auth.getSession();
    if (data && data.session !== null) {
      setSession(data);
      dispatchRedux(setUserId(data.session.user.id));
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUsersInfo = async (uid) => {
    const { data, error } = await supabase
      .from("admin")
      .select("*")
      .eq("auth_id", uid);

    if (data.length) {
      const admin = data[0];
      const adminName = {
        firstName: admin.first_name,
        lastName: admin.last_name,
      };
      dispatchRedux(setName(adminName));
      if(companyID === ""){
        dispatchRedux(setCompany(admin.company_id));
      }
      if(admin.company_id === 1){
        dispatchRedux(setIsCalistoUser("true"));
      }

      //Update login date
      const { error } = await supabase
        .from("admin")
        .update({ last_login_at: formatISO(new Date()) })
        .eq("id", admin.id);
    }
  };

  if (loading === true) {
    return (
      <MDBox mt={4} mb={1} textAlign="center">
        <CircularProgress />{" "}
      </MDBox>
    );
  } else if (userId === "") {
    //If user is not found redirect to login page
    return (
      <Navigate to="/login" state={{ path: location.pathname }}></Navigate>
    );
  } else {
    //Update user info if login found
    setUsersInfo(userId);
  }

  // Go to child elements
  return children;
};
