import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    firstName: "",
    lastName: "",
    companyID: "",
    userId: "",
    isCalistoUser:"",
    selectedCompanyView:"Calisto",
  },
  reducers: {
    clearName: state => {
      state.firstName = "";
      state.lastName = "";
    },
    setName: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setCompany: (state, action) => {
      state.companyID = action.payload;
    },
    clearCompany: state => {
      state.companyID = "";
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    clearUserId: state => {
      state.userId = "";
    },
    setIsCalistoUser: (state, action) => {
      state.isCalistoUser = action.payload;
    },
    clearIsCalistoUser: state => {
      state.isCalistoUser = "false";
    },
    /*setSelectedCompanyView: (state, action) => {
      state.selectedCompanyView = action.payload;
    },
    clearSelectedCompanyView: state => {
      state.selectedCompanyView = "Calisto";
    },*/
  }
})

// Action creators are generated for each case reducer function
export const { clearName, setName, setCompany, clearCompany, setUserId, clearUserId, setIsCalistoUser, clearIsCalistoUser, /*setSelectedCompanyView, clearSelectedCompanyView*/} = userSlice.actions

export default userSlice.reducer