import React from 'react';


export default function SnapshotPoint({color = '#000000'}) {
    const K_WIDTH = 0.5;
    const K_HEIGHT = 0.5;
    
    const snapshotPointStyle = {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: 'absolute',
      width: K_WIDTH,
      height: K_HEIGHT,
      left: -K_WIDTH / 2,
      top: -K_HEIGHT / 2,
    
      border: `5px solid ${color}`,
      borderRadius: 10,
      backgroundColor: color,
      textAlign: 'center',
      color: color,
      fontSize: 1,
      fontWeight: 'bold',
      padding: 0
    };

    return (
       <div style={snapshotPointStyle}>
       </div>
    );
}