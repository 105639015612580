/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";

// ReportsBarChart configurations
import configs from "page_components/Charts/BarCharts/ChemicalUsageBarChart/configs";

//React
import React, { useState, useEffect } from "react";

//Redux
import { useSelector } from "react-redux";

import { format } from "date-fns";

//Supabase
import { supabase } from "supabaseClient";

function ChemicalUsageBarChart({ color, title, description, date }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const [labels, setLabels] = useState([
    "Loading...",
    "Loading...",
    "Loading...",
    "Loading...",
    "Loading...",
    "Loading...",
    "Loading...",
  ]);

  const [datasets, setDatasets] = useState({
    label: "Gallons Sprayed",
    data: [0, 0, 0, 0, 0, 0, 0],
  });
  const [data, setData] = React.useState({
    labels,
    datasets: [
      {
        label: "Gallons Sprayed",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });
  const [options, setOptions] = React.useState({});
  const [selectedChemicalName, setSelectedChemicalName] = React.useState("");
  const [selectedChemicalId, setSelectedChemicalId] = React.useState(-1);
  const [selectedChemMixUnits, setSelectedChemMixUnits] =
    React.useState("Amount");
  const [endDate, setEndDate] = React.useState({ date: new Date() });
  const [chemicals, setChemicals] = React.useState([]);
  const companyID = useSelector((state) => state.user.companyID);

  const updateChart = (labels, datasets) => {
    const { data, options } = configs(labels || [], datasets || {});
    setData(data);
    setOptions(options);
  };

  const handleChemicalChange = (event) => {
    setSelectedChemicalName(event.target.value);
    let foundChemical = chemicals.find((o) => o.name === event.target.value);
    const chemMixUnits = foundChemical.chem_mix_units;
    setSelectedChemicalId(foundChemical.id);
    setSelectedChemMixUnits(
      chemMixUnits?.charAt(0).toUpperCase() + chemMixUnits.slice(1)
    );
  };

  const changeDate = async (endDate) => {
    setEndDate({ date: endDate });
  };

  //Initial load
  useEffect(() => {
    getChemicalList();
    updateChart(labels, datasets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyID]);

  //Selected date changes
  useEffect(() => {
    getWeeklyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, selectedChemicalName]);

  useEffect(() => {
    updateChart(labels, datasets);
  }, [labels, datasets]);

  useEffect(() => {
    if (chemicals && chemicals.length > 0) {
      setSelectedChemicalName(chemicals[0].name);
      setSelectedChemicalId(chemicals[0].id);
      const chemicalUnits = chemicals[0].chem_mix_units;
      setSelectedChemMixUnits(
        chemicalUnits?.charAt(0).toUpperCase() + chemicalUnits.slice(1)
      );
    }
  }, [chemicals]);

  const getChemicalList = async () => {
    //Get a company record and load the names of the chemicals

    var chemicalList = [];
    const { data, error } = await supabase
      .from("supported_chemicals")
      .select(
        "chemical(id, application_rate, application_units, base_unit, chem_mix_rate, chem_mix_units, name, feet_sprayed_up, feet_sprayed_out)"
      )
      .eq("company_id", companyID);

    for (var i = 0; i < data.length; i++) {
      chemicalList.push(data[i].chemical);
    }

    if (chemicalList.length > 0) {
      setChemicals(chemicalList);
    }
  };

  const getWeeklyData = async () => {
    if (selectedChemicalName === "") {
      return;
    }

    let days = [];
    let amountsSprayed = [];
    //Get current day

    let tomorrowAtMidnight = new Date(endDate.date.getTime());
    tomorrowAtMidnight.setDate(endDate.date.getDate() + 1);
    tomorrowAtMidnight.setHours(0, 0, 0, 0);
    //For each of the past 7 days
    for (let i = 1; i < 8; i++) {
      let currentDay = new Date(endDate.date.getTime());
      currentDay.setDate(tomorrowAtMidnight.getDate() - i);
      currentDay.setHours(12, 0, 0, 0);
      //Get the day of the week and add it an array
      days.push(format(currentDay, "MMM do"));
      //Get the total gallons sprayed
      let morning = new Date();
      let night = new Date();
      morning.setDate(currentDay.getDate());
      morning.setHours(0, 0, 0, 0);
      night.setDate(currentDay.getDate());
      night.setHours(24, 0, 0, 0);

      const { data, error } = await supabase
        .from("service")
        .select()
        .eq("company_id", companyID)
        .eq("chem_id", selectedChemicalId)
        .lt("start_time", night.toISOString())
        .gte("start_time", morning.toISOString());

      let chemicalUsage = 0;
      if (data) {
        data.forEach((doc) => {
          if (
            doc.time_spent_spraying > 10 &&
            doc.total_gallons_sprayed > 0.01
          ) {
            chemicalUsage += doc.chem_mix_rate * doc.total_gallons_sprayed;
          }
        });
      }
      amountsSprayed.push(chemicalUsage.toFixed(1));
    }

    setLabels(days.reverse());
    setDatasets({
      label: selectedChemMixUnits + " of Chemical Used",
      data: amountsSprayed.reverse(),
    });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              <Bar data={data} options={options} />
            </MDBox>
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [data, color]
        )}
        <MDBox pt={1} pb={1} px={1}>
          <MDBox textAlign="left" lineHeight={1.25} pl={2} pt={2} pb={2}>
            <MDTypography variant="h6" textTransform="capitalize">
              {title}
            </MDTypography>
            <MDTypography
              component="div"
              variant="button"
              color="text"
              fontWeight="light"
            >
              {selectedChemMixUnits + " of Chemical Used"}
            </MDTypography>
          </MDBox>
          <Divider />
          <MDBox display="flex" alignItems="center">
            {/*<MDTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>*/}
            <MDBox textAlign="left" pr={2} pl={1}>
              <FormControl
                variant="outlined"
                margin="dense"
                sx={{ width: 200 }}
              >
                <InputLabel id="demo-simple-select-label">Chemical</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedChemicalName}
                  label="Chemical"
                  onChange={handleChemicalChange}
                  size="medium"
                  sx={{ height: 40 }}
                >
                  {chemicals.map((chemical, index) => {
                    return (
                      <MenuItem key={chemical.id} value={chemical.name}>
                        {chemical.name}
                      </MenuItem>
                    );
                  })}
                  ;
                </Select>
              </FormControl>
            </MDBox>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ height: 40, width: 250 }}
                label="End Date"
                value={endDate.date}
                maxDate={new Date()}
                onChange={(newValue) => {
                  changeDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ChemicalUsageBarChart.defaultProps = {
  color: "primary",
  description: "",
};

// Typechecking props for the ReportsBarChart
ChemicalUsageBarChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
};

export default ChemicalUsageBarChart;
