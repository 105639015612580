import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DetailsTable from "page_components/Tables/DetailsTable";
import MDTypography from "theme_components/MDTypography";
//import Map, { Source, Layer } from "react-map-gl";
import MDBox from "theme_components/MDBox";
import MDButton from "theme_components/MDButton";

//import mapboxgl from "mapbox-gl";
import { supabase } from "supabaseClient";
//import { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import SnapshotPoint from "page_components/SnapshotPoint";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
//mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function ServiceDialog({
  date = "",
  time = "",
  techName = "",
  techEmail = "",
  sprayVol = "",
  chemical = "",
  linFeet = "",
  timeSpraying = "",
  street = "",
  city = "",
  state = "",
  zipCode = "",
  serviceId = "",
  lat = "",
  long = "",
}) {
  const [snapshots, setSnapshots] = React.useState([]);

  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: false,
      fullscreenControl: false,
      tilt: 0,
      rotateControl: false,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      minZoom: 16,
      maxZoom: 23,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      zoomControl: true,
      clickableIcons: false,
    };
  };

  const fetchSnapshots = async () => {
    const { data, error } = await supabase
      .from("snapshot")
      .select("*")
      .eq("service_id", serviceId)
      .order("time_stamp", { ascending: true });
    if (error) {
      console.log(error);
    }
    setSnapshots(data);
  };
  const columns = [
    { Header: "label", accessor: "label", align: "center" },
    { Header: "data", accessor: "data", align: "center" },
  ];

  const rows = [
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Date
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {date}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Time
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {time}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Technician Name
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {techName}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Technician Email
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {techEmail}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Spray Volume
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {sprayVol}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Pesticide Type
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {chemical}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Linear Feet
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {linFeet}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Time Spent Spraying
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {timeSpraying}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Street
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {street}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          City
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {city}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          State
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {state}
        </MDTypography>
      ),
    },
    {
      label: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Zip Code
        </MDTypography>
      ),
      data: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {zipCode}
        </MDTypography>
      ),
    },
  ];

  const [open, setOpen] = React.useState(false);
  //const MAPBOX_TOKEN =
  //  "pk.eyJ1IjoiYmVuY3VsbGVuIiwiYSI6ImNsM2J4a2NtNzA5Ym8zY28yOHdwM3YwODEifQ.4uBc9-ZInfCB-o_LGvVycA";

  const handleClickOpen = async () => {
    await fetchSnapshots();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //var coordinateData = snapshots.map((snapshot) => [snapshot.longitude, snapshot.latitude]);
  /*var snapshotCoordinates = snapshots
    .filter((snapshot) => snapshot.flowRate > 0)
    .map((snapshot) => [snapshot.longitude, snapshot.latitude]);*/

  var hideNonFlowPoints = false;

  var snapshotData = [];

  if (hideNonFlowPoints) {
    snapshotData = snapshots.filter((snapshot) => snapshot.flowRate > 0);
  } else {
    snapshotData = snapshots;
  }

  /* const snapshotGeojson = {
    type: "FeatureCollection",
    features: [],
  };

  for (var i = 0; i < snapshotData.length; i++) {
    snapshotGeojson.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [snapshotData[i].longitude, snapshotData[i].latitude],
      },
      properties: {
        flow_rate: snapshotData[i].flow_rate,
      },
    });
  }

  const snapshotPointStyle = {
    id: "points",
    type: "circle",
    source: "snapshots",
    paint: {
      "circle-color": [
        "case",
        [">", ["get", "flow_rate"], 0],
        "#c61a09",
        "#6b6868",
      ],
      "circle-radius": ["case", [">", ["get", "flow_rate"], 0], 3, 3],
    },
  };*/

  return (
    <div>
      <MDBox m={1}>
        <MDButton variant="gradient" color="info" onClick={handleClickOpen}>
          View Details
        </MDButton>
      </MDBox>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{ pt: "50px", pr: "30px", pb: "50px", pl: "80px" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Service Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {isNaN(long) === false && isNaN(lat) === false ? (
            <div style={{ height: "400px", width: "500px" }}>
              <GoogleMap
                apiKey={"AIzaSyCbLeFA3pX2i2MoCMbBAYJRwmblt0rS-3M"}
                options={getMapOptions}
                center={[lat, long]}
                zoom={19}
              >
                {snapshotData.map(({ latitude, longitude, flow_rate, id }) =>
                  flow_rate > 0 ? (
                    <SnapshotPoint
                      key={id}
                      lat={latitude}
                      lng={longitude}
                      color={"#c61a09"}
                    />
                  ) : (
                    <SnapshotPoint
                      key={id}
                      lat={latitude}
                      lng={longitude}
                      color={"#414141"}
                    />
                  )
                )}
              </GoogleMap>
            </div>
          ) : null}
          {/*<Map
            initialViewState={{
              longitude:
                snapshotCoordinates[0] != null
                  ? snapshotCoordinates[0][0]
                  : snapshots.length
                  ? snapshots[0].longitude
                  : 40.2338,
              latitude:
                snapshotCoordinates[0] != null
                  ? snapshotCoordinates[0][1]
                  : snapshots.length
                  ? snapshots[0].latitude
                  : 1.0,
              zoom: 18,
            }}
            style={{ width: 500, height: 300 }}
            mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
            mapboxAccessToken={MAPBOX_TOKEN}
          >
            <Source id="snapshot-points" type="geojson" data={snapshotGeojson}>
              <Layer {...snapshotPointStyle} />
            </Source>
          </Map>*/}
          <MDBox sx={{ height: "30px" }}></MDBox>
          <DetailsTable
            table={{ columns: columns, rows: rows }}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
