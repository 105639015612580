// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import TabPanel from "page_components/TabPanel";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";

//Redux
import { useSelector } from "react-redux";

//React
import { useEffect, useState } from "react";

//Supabase
import { supabase } from "supabaseClient";

import breakpoints from "assets/theme/base/breakpoints";

function ManageAccountsCard() {
  const companyID = useSelector((state) => state.user.companyID);
  const [loading, setLoading] = useState(true);
  const [technicianList, setTechnicianList] = useState([]);
  const [techRows, setTechRows] = useState([]);
  const [techRowCount, setTechRowCount] = useState(0);
  const [techCurrentPage, setTechCurrentPage] = useState(0);
  const [adminList, setAdminList] = useState([]);
  const [adminRows, setAdminRows] = useState([]);
  const [adminRowCount, setAdminRowCount] = useState(0);
  const [adminCurrentPage, setAdminCurrentPage] = useState(0);
  const [availableLicenses, setAvailableLicenses] = useState(0);
  const [usedLicenses, setUsedLicenses] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const page_size = 10;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    //Get docs
    fetchRows(techCurrentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyID]);

  useEffect(() => {
    renderTechRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicianList]);

  useEffect(() => {
    renderAdminRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminList]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const fetchRows = async (newPage) => {
    setLoading(true);
    const { from, to } = getPagination(newPage, page_size);

    //Get Technicians
    const {
      data: techData,
      count: techCount,
      error: techError,
    } = await supabase
      .from("technician")
      .select("*", {
        count: "exact",
      })
      .eq("company_id", companyID)
      .order("first_name", { ascending: false })
      .range(from, to);

    if (techError) {
      console.log(techError);
    }
    setTechnicianList(techData);
    setTechRowCount(techCount);

    //Get Admins
    const {
      data: adminData,
      count: adminCount,
      error: adminError,
    } = await supabase
      .from("admin")
      .select("*", {
        count: "exact",
      })
      .eq("company_id", companyID)
      .order("first_name", { ascending: false })
      .range(from, to);

    if (adminError) {
      console.log(adminError);
    }
    setAdminList(adminData);
    setAdminRowCount(adminCount);

    var enabledAccounts = [];

    for (let index = 0; index < techData.length; index++) {
      const doc = techData[index];
      if (doc.status === "enabled") {
        enabledAccounts.push(doc.auth_id);
      }
    }

    setUsedLicenses(enabledAccounts.length);

    const { data: companyData, error: companyError } = await supabase
      .from("company")
      .select("account_licenses")
      .eq("id", companyID);

    if (companyError) {
      console.log(companyError);
    }
    setAvailableLicenses(companyData[0].account_licenses);
    setLoading(false);
  };

  const updateTechStatus = async (id, status) => {
    const { error } = await supabase
      .from("technician")
      .update({ status: status })
      .eq("id", id);
    if (error) {
      console.log(error);
    }
  };

  const updateAdminStatus = async (id, status) => {
    const { error } = await supabase
      .from("admin")
      .update({ status: status })
      .eq("id", id);
    if (error) {
      console.log(error);
    }
  };

  const getPagination = (page, size) => {
    const limit = size ? +size : 3;
    const from = page ? page * limit : 0;
    const to = page ? from + size - 1 : size - 1;

    return { from, to };
  };

  const handleTechPageChange = (paginationModel) => {
    if (loading === false) {
      setTechCurrentPage(paginationModel.page);
      fetchRows(paginationModel.page);
    }
  };

  const handleAdminPageChange = (paginationModel) => {
    if (loading === false) {
      setAdminCurrentPage(paginationModel.page);
      fetchRows(paginationModel.page);
    }
  };

  const renderTechRows = () => {
    if (techRows.length > 0) {
      setTechRows([]);
    }
    var items = [];
    for (let index = 0; index < technicianList.length; index++) {
      const doc = technicianList[index];
      //const rowNum = rowIndex + index;
      items.push({
        id: doc.id,
        firstName: doc.first_name,
        lastName: doc.last_name,
        email: doc.email,
        status: doc.status,
      });
    }
    setTechRows(Array.from(items));
  };

  const renderAdminRows = () => {
    if (adminRows.length > 0) {
      setAdminRows([]);
    }
    var items = [];
    for (let index = 0; index < adminList.length; index++) {
      const doc = adminList[index];
      //const rowNum = rowIndex + index;
      items.push({
        id: doc.id,
        firstName: doc.first_name,
        lastName: doc.last_name,
        email: doc.email,
        status: doc.status,
      });
    }
    setAdminRows(Array.from(items));
  };

  const adminColumns = [
    { field: "id", headerName: "ID", width: 0, sortable: false },
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: false,
    },
  ];

  const techColumns = [
    { field: "id", headerName: "ID", width: 0, sortable: false },
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: ["enabled", "disabled"],
      valueParser: (value, params) => {
        var shouldUpdate = false;
        if (value === "disabled") {
          setUsedLicenses(usedLicenses - 1);
          shouldUpdate = true;
        } else {
          if (usedLicenses + 1 > availableLicenses) {
            alert(
              "This account cannot be enabled because it will exceed the license amount. Contact support to get more licenses or disable an active account."
            );
          } else {
            setUsedLicenses(usedLicenses + 1);
            shouldUpdate = true;
          }
        }
        if (shouldUpdate) {
          updateTechStatus(params.id, value.toLowerCase());
          return value.toLowerCase();
        } else {
          return "disabled";
        }
      },
    },
  ];

  return (
    <Card sx={{ height: "100%", width: "100%", boxShadow: "none" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
        pb={1}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Manage Accounts
        </MDTypography>
      </MDBox>
      <AppBar position="static">
        <Tabs
          orientation={tabsOrientation}
          value={tabValue}
          onChange={handleSetTabValue}
        >
          <Tab
            label="Admins"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                person
              </Icon>
            }
          />
          <Tab
            label="Technicians"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                business
              </Icon>
            }
          />
        </Tabs>
      </AppBar>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} xl={12}>
          <TabPanel value={tabValue} index={0}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={adminRows}
                columns={adminColumns}
                pageSizeOptions={[page_size]}
                rowCount={adminRowCount}
                paginationMode="server"
                paginationModel={{page: adminCurrentPage, pageSize: page_size}}
                onPaginationModelChange={handleAdminPageChange}
                loading={loading}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                //checkboxSelection
                disableSelectionOnClick
                keepNonExistentRowsSelected
                sortingMode="server"
                getRowHeight={({ id, densityFactor }) => {
                  return 50;
                }}
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MDTypography variant="body1">
              Using {usedLicenses} of {availableLicenses} technician licenses
            </MDTypography>
            <MDTypography variant="body2">
              Double click cells in "Status" column to enable or disable
              accounts
            </MDTypography>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={techRows}
                columns={techColumns}
                pageSizeOptions={[page_size]}
                rowCount={techRowCount}
                paginationMode="server"
                paginationModel={{page: techCurrentPage, pageSize: page_size}}
                onPaginationModelChange={handleTechPageChange}
                loading={loading}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                //checkboxSelection
                disableSelectionOnClick
                keepNonExistentRowsSelected
                sortingMode="server"
                getRowHeight={({ id, densityFactor }) => {
                  return 50;
                }}
              />
            </Box>
          </TabPanel>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ManageAccountsCard;
